import React, { useContext } from "react";
import { Link } from "gatsby";
import localeSlug from "../../utils/localeSlug";
import LocaleContext from "../../context/locale";

function NotFoundPage({ messages }) {
  const { langKey } = useContext(LocaleContext);

  return (
    <div className="flex h-screen text-white text-center">
      <div className="m-auto ">
        <h1 className="text-5xl tracking-widest mb-4">{messages.title}</h1>
        <h2 className="font-roboto text-lg text-gray-300 tracking-wider my-4">
          {messages.subtitle}
        </h2>
        <div className="my-11 font-roboto text-gray-300">
          <Link to={localeSlug(langKey, "/")}>Go home</Link>.
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
