import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import NotFoundPage from "../../components/NotFoundPage";

function NotFound404({ location, data }) {
  return (
    <Layout location={location} languages={data.site.siteMetadata.languages}>
      <NotFoundPage messages={data.es} />
    </Layout>
  );
}

export default NotFound404;

export const pageQuery = graphql`
  query Page404EsQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    es: datoCmsNotFound404(locale: { eq: "es" }) {
      title
      subtitle
    }
  }
`;
